'use client'

import { Fragment, ElementType, forwardRef, ComponentProps, FC } from 'react'
import { useLoader } from '@/providers'

interface Props extends ComponentProps<'a'> {
  as?: ElementType | FC
  nofollow?: boolean
  noopener?: boolean
}

export default forwardRef<HTMLAnchorElement, Props>(function Link(
  {
    as: FallbackComponent,
    rel,
    target,
    href,
    children,
    nofollow = false,
    noopener = false,
    ...props
  },
  ref
) {
  const { setLoading } = useLoader()
  if (!href) {
    if (FallbackComponent) {
      return <FallbackComponent {...props}>{children}</FallbackComponent>
    }
    return <Fragment>{children}</Fragment>
  }

  const rels = []
  if (nofollow) rels.push('nofollow')
  if (noopener) rels.push('noopener')

  if (!rel && rels.length > 0) {
    rel = rels.join(' ')
  }

  if (target === '_self' || target === '') {
    target = undefined
  }

  const onClick = () => {
    if (target === undefined) {
      setLoading(true)
    }
  }
  return (
    <a
      ref={ref}
      target={target}
      onClick={onClick}
      rel={rel}
      href={href}
      {...props}
    >
      {children}
    </a>
  )
})
