import { forwardRef } from 'react'
import { IconButton, IconButtonProps } from '@material-tailwind/react'
import Icon, { IconProps } from '@/components/Icon'

interface Props extends Partial<IconButtonProps> {
  iconProps?: IconProps
}

export default forwardRef<HTMLButtonElement, Props>(function NavButton(
  { iconProps, className = '', ...props },
  ref
) {
  const {
    icon,
    className: iconClassName = '',
    size: iconSize = '17px',
    ...iconPropsRest
  } = iconProps! ?? {}

  return (
    <IconButton
      className={`!absolute top-1/2 z-20 h-[35px] w-[31px] -translate-y-1/2 ${className}`}
      {...props}
      ref={ref}
    >
      <Icon
        className={`block ${iconClassName}`}
        icon={icon}
        size={iconSize}
        {...iconPropsRest}
      />
    </IconButton>
  )
})
