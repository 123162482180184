'use client'

import { Filters } from '@/types'
import Accordion from '@/components/Accordion'
import SearchInput from '@/components/SearchInput'
import React, { useState, useEffect } from 'react'
import { Typography, Button } from '@material-tailwind/react'
import { useDictionary } from '@/providers'
import { OffCanvas } from './OffCanvas'
import HorizontalSelect from './HorizontalSelect'
import { useFilterSearch } from '../use-filter-search'
import Loader from '@/components/Loader'
import FakeCheckbox from '@/components/FakeCheckbox'
import { CustomList, CustomListItem } from '@/components/CustomList'

type Props = {
  title: string
  name: string
  onFilter: Filters.onFilter
  onResetFilter: Filters.onResetFilter
  onShowMoreFilters: Filters.onShowMoreFilters
  items: Array<Filters.FilterItemCheckboxListItem>
  filter: Filters.SelectedData
  mainContentFilter: boolean
  mobile?: boolean
  horizontal?: boolean
  searchable?: boolean
  urlPosition?: number | null
  selected?: Array<string>
  group?: Filters.Group
}

export default function CheckboxList({
  title,
  name,
  items,
  filter,
  searchable,
  mobile = false,
  urlPosition = null,
  horizontal = false,
  mainContentFilter = false,
  onFilter,
  onResetFilter,
  onShowMoreFilters,
  selected = [],
  group,
}: Props) {
  const d = useDictionary('filters')
  const [listItems, setListItems] = useState<
    Array<Filters.FilterItemCheckboxListItem>
  >(items || [])
  const [loading, setLoading] = useState({
    search: false,
    button: false,
    loadMore: false,
  })
  const [getListItemsSuccess, setGetListItemsSuccess] = useState(false)

  const { filteredData, applyFilterSearch } = useFilterSearch(listItems)
  const [offset, setOffset] = useState(1)
  const [checkedItems, setCheckedItems] = useState<
    Array<Filters.FilterItemCheckboxListItem>
  >([])
  const [checkedSlugs, setCheckedSlugs] = useState<Array<string>>(
    selected || []
  )

  const _filteredData =
    (horizontal || mobile) && offset * 50 > filteredData.length
      ? filteredData
      : filteredData.slice(0, offset * 50)

  useEffect(() => {
    if (selected?.length > 0) {
      const selectedItems: Array<Filters.FilterItemCheckboxListItem> = []
      selected?.forEach((slug) => {
        const selectedItem = filteredData?.find((item) => item.slug === slug)
        if (selectedItem) {
          selectedItems.push(selectedItem)
        }
      })

      setCheckedItems(selectedItems)

      if (selectedItems.length) {
        onFilter(
          name,
          {
            urlPosition: urlPosition,
            title,
            selected: selectedItems,
          },
          true,
          group
        )
      }
    }
  }, [])

  useEffect(() => {
    if (!Object.keys(filter)?.length && checkedItems?.length) {
      setCheckedItems([])
      setCheckedSlugs([])
    } else if (
      Object.keys(filter)?.length &&
      filter?.selected?.length < checkedItems?.length
    ) {
      setCheckedItems(filter?.selected)
      const slags = filter?.selected?.map(
        (item: Filters.FilterItemCheckboxListItem) => item.slug
      )
      setCheckedSlugs(slags)
    }
  }, [filter?.selected])

  const handleSelectItem = (
    value: boolean,
    item: Filters.FilterItemCheckboxListItem
  ) => {
    let newCheckedItems = [...checkedItems]
    let slugs = [...checkedSlugs]

    if (value) {
      newCheckedItems.push(item)
      slugs.push(item.slug)
    } else {
      newCheckedItems = newCheckedItems.filter((i) => {
        return i.id !== item.id
      })
      slugs = slugs.filter((i: string) => {
        return i !== item.slug
      })
    }

    setCheckedItems(newCheckedItems)
    setCheckedSlugs(slugs)

    if (newCheckedItems.length) {
      onFilter(
        name,
        {
          urlPosition: urlPosition || null,
          title,
          selected: newCheckedItems,
        },
        mobile,
        group
      )
    } else {
      onResetFilter(name, !mobile, group)
    }
  }

  const handleScroll = (event: any) => {
    const target = event.currentTarget as HTMLDivElement
    const mobileCoef = mobile ? 1 : 0

    if (
      target.scrollHeight - target.scrollTop - mobileCoef <=
      target.clientHeight
    ) {
      const _offset = offset + 1
      setOffset(_offset)
    }
  }

  const handleShowMore = async (type: string) => {
    setLoading({
      ...loading,
      [type]: true,
    })

    const newListItems = await onShowMoreFilters(name)

    setListItems(newListItems)
    setGetListItemsSuccess(true)
    setLoading({
      ...loading,
      [type]: false,
    })

    return true
  }

  const handleSearch = async (value: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    mainContentFilter && !getListItemsSuccess
      ? await handleShowMore('search')
      : () => {}
    applyFilterSearch(value)
  }

  const showMore =
    mainContentFilter && listItems?.length <= 0 ? handleShowMore : () => {}

  const renderContent = (
    <div className='relative w-full focus-visible:outline-none'>
      {searchable && (
        <div className='mb-3'>
          <SearchInput
            onChange={handleSearch}
            ariaLabel={d('hidden')}
            searchIconSize='12px'
            inputClassName='text-md !px-7 !py-1 mb-3'
            searchIconClassName='!left-1'
            cleaButtonClassName='h-5 w-5'
            disabled={loading.search}
            containerProps={{
              className: 'h-7',
            }}
          />
        </div>
      )}

      <div
        className={`${!searchable && !horizontal ? 'border-t' : ''} ${
          !mobile && !horizontal ? 'max-h-[33.375rem] overflow-y-auto' : ''
        }`}
      >
        {!_filteredData?.length ? (
          <Typography variant='lead' className='p-2'>
            {d('not_found')}
          </Typography>
        ) : (
          <CustomList className='p-0'>
            {_filteredData.map((el, index) => (
              <CustomListItem
                key={index}
                className='rounded-none p-0'
                title={el.title || el.value}
                aria-label={el.title || el.value}
              >
                <FakeCheckbox
                  checked={checkedSlugs?.includes(el.slug)}
                  ripple={false}
                  onChange={(checked) => handleSelectItem(checked, el)}
                  label={el.value || el.title}
                />
              </CustomListItem>
            ))}
          </CustomList>
        )}
      </div>

      {mainContentFilter && !getListItemsSuccess && (
        <Button
          loading={loading.button}
          className='mt-4 flex h-8 w-full items-center justify-center border px-2 py-1 text-center text-sm font-medium capitalize text-secondary !shadow-none'
          onClick={() => handleShowMore('button')}
          aria-label={d('more_filters')}
        >
          {d('more_filters')}
        </Button>
      )}

      <Loader
        loading={loading.search}
        className='absolute bg-black/10'
        iconClassName='!fill-black'
      />
    </div>
  )

  if (mobile) {
    return (
      <OffCanvas
        title={title}
        handleScroll={handleScroll}
        filterCount={checkedItems.length}
        onClick={() => showMore('loadMore')}
        loading={loading.loadMore}
      >
        {renderContent}
      </OffCanvas>
    )
  }

  if (horizontal) {
    return (
      <HorizontalSelect
        title={title}
        filterCount={checkedItems.length}
        handleScroll={handleScroll}
        onClick={() => showMore('loadMore')}
        loading={loading.loadMore}
      >
        {renderContent}
      </HorizontalSelect>
    )
  }

  return (
    <Accordion
      title={title}
      active={!!selected?.length || !!items?.length}
      icon='plus'
      collapsedIcon='minus'
      className='m-0 border px-3'
      headerClassName='text-base border-0'
      onClick={() => showMore('loadMore')}
      loading={loading.loadMore}
    >
      {renderContent}
    </Accordion>
  )
}
