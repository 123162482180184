'use client'

import { ReactNode, forwardRef, useState } from 'react'
import { Swiper, type SwiperProps, SwiperRef } from 'swiper/react'
import type { Swiper as SwiperClass } from 'swiper/types'
import { Navigation } from 'swiper/modules'
import NavButton from './NavButton'
import tailwindConfig from '@/utils/tailwind-config'
import pxToNum from '@/utils/px-to-num'

interface Props extends SwiperProps {
  children: ReactNode
  className?: string
  navPrevClassName?: string
  navNextClassName?: string
  imageEl?: string
  navPrevProps?: object
  navNextProps?: object
}

export const breakpoints = {
  sm: pxToNum(tailwindConfig.theme.screens.sm),
  md: pxToNum(tailwindConfig.theme.screens.md),
  lg: pxToNum(tailwindConfig.theme.screens.lg),
  xl: pxToNum(tailwindConfig.theme.screens.xl),
  xxl: pxToNum(tailwindConfig.theme.screens['2xl']),
}

export { SwiperSlide as SliderItem } from 'swiper/react'
export { SwiperProps as SliderProps }

export default forwardRef<SwiperRef, Props>(function Slider(
  {
    children,
    className = '',
    navPrevClassName = '',
    navNextClassName = '',
    imageEl = '',
    navPrevProps = {},
    navNextProps = {},
    ...sliderOptions
  },
  ref
) {
  const [navTop, setNavTop] = useState<number>(0)
  const sliderProps = {
    modules: [Navigation],
    navigation: {
      prevEl: `.slider-nav-button-prev`,
      nextEl: `.slider-nav-button-next`,
      enabled: true,
      hiddenClass: 'hidden',
      disabledClass: 'hidden',
    },
    ...sliderOptions,
  }

  if (
    imageEl &&
    typeof sliderProps?.navigation === 'object' &&
    sliderProps?.navigation?.enabled
  ) {
    const calculateNavTop = (swiper: SwiperClass) => {
      const image = swiper.slides[swiper.activeIndex].querySelector(imageEl)
      if (image instanceof HTMLElement) {
        setNavTop(image.clientHeight / 2)
      }
    }

    sliderProps.onBeforeResize = calculateNavTop
    sliderProps.onInit = calculateNavTop
  }

  return (
    <Swiper ref={ref} className={`relative ${className}`} {...sliderProps}>
      {typeof sliderProps?.navigation === 'object' &&
        sliderProps?.navigation?.enabled && (
          <NavButton
            className={`slider-nav-button-prev left-0 top-1/2 hidden ${navPrevClassName}`}
            style={imageEl ? { top: navTop } : undefined}
            iconProps={{ icon: 'arrow-left2' }}
            aria-label='Previous slide'
            {...navPrevProps}
          />
        )}
      {children}
      {typeof sliderProps?.navigation === 'object' &&
        sliderProps?.navigation?.enabled && (
          <NavButton
            className={`slider-nav-button-next right-0 top-1/2 hidden ${navNextClassName}`}
            style={imageEl ? { top: navTop } : undefined}
            iconProps={{ icon: 'arrow-right2' }}
            aria-label='Next slide'
            {...navNextProps}
          />
        )}
    </Swiper>
  )
})
