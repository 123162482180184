'use client'

import { useEffect, useState } from 'react'
import { Page as PageTypes } from '@/types'
import Content from '@/containers/layouts/Page/Content'
import Loader from '@/components/Loader'
import { PageProvider, useLoader } from '@/providers'

type Props = {
  page: PageTypes.Page
}

export default function Page({ page }: Props) {
  const loader = useLoader()
  const [loading, setLoading] = useState<boolean>(loader.loading)

  useEffect(() => {
    setLoading(loader.loading)
  }, [loader.loading])

  useEffect(() => {
    const showLoader = () => {
      setLoading(true)
    }
    const hideLoader = () => {
      setLoading(false)
    }
    const handlePageShow = (event: PageTransitionEvent) => {
      if (event.persisted) {
        hideLoader()
      }
    }

    if (undefined !== window) {
      window.addEventListener('beforeunload', showLoader)
      window.addEventListener('pageshow', handlePageShow)
    }

    return () => {
      if (undefined !== window) {
        window.removeEventListener('beforeunload', showLoader)
        window.removeEventListener('pageshow', handlePageShow)
      }
    }
  }, [])

  return (
    <PageProvider page={page}>
      <Content page={page} />
      <Loader
        className='z-[9999] bg-transparent backdrop-blur-sm'
        iconClassName='!fill-black'
        loading={loading}
      />
    </PageProvider>
  )
}
