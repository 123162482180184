export type FilterValue = {
  urlPosition: number | null
  title: string | undefined | null
  selected: any
}

export type Facets = {
  top: Array<FilterItem>
  left: Array<FilterItem>
  level1: Array<FilterItem>
  level2: Array<FilterItem>
}

export enum Type {
  TREE = 'tree',
  RANGE = 'range',
  CHECKBOX = 'checkbox',
  COLOR = 'color',
  LIST = 'list',
  ORDER = 'order',
  CHECKBOX_LIST = 'checkbox-list',
  CATEGORY_MODAL = 'category-modal',
}

export interface FilterItem {
  name: string
  searchable: boolean
  count: number
  url: string
  title: string
  type: Type
  slug: string
  value: string
  indexable: boolean
  mainContentFilter: boolean
  selected: any
  id: number
  urlPosition: number
  children: Array<FilterItem>
}

export interface CombinationFilterItem {
  title: string
  filters: Array<FilterItem>
  group?: Group
}

export type Color = {
  colorHex: string
  id: number
  slug: string
  value: string
}

export type Range = {
  minPrice: number
  maxPrice: number
}

export type Order = {
  name: string
  value: number
}

export interface FilterItemRange extends FilterItem {
  values: Range
}

export interface FilterItemTree extends FilterItem {
  urlPosition: number
  values: Array<FilterItem>
}

export interface FilterItemOrder extends FilterItem {
  values: Array<Order>
}

export interface FilterItemCheckboxList extends FilterItem {
  urlPosition: number
  values: Array<FilterItemCheckboxListItem>
}

export type FilterItemListItem = FilterItem

export interface FilterItemCheckboxListItem extends FilterItem {
  id: number
}

export interface FilterItemColor extends FilterItem {
  urlPosition: number
  values: Array<Color>
}

export interface FilterCount {
  total: number
  groups: {
    [key: string]: number
  }
  other: number
}

export type onFilter = (
  name: string,
  value: FilterValue,
  changeUrl?: boolean,
  group?: Group | undefined
) => void

export type onResetFilter = (
  name: string,
  changeUrl?: boolean,
  group?: Group
) => void

export type onResetAllFilter = (group?: Group) => void

export type onShowMoreFilters = (componentName: string) => any

export type onApplyFilters = () => void

export type Group = {
  name?: string
  position?: number
  categoryPopupTitle?: string
}

export type GroupPositions = {
  [key: string]: number
}

export enum Fields {
  hasPrice = 'price',
}

export enum GroupNames {
  OTHER = 'other',
}

export interface SelectedData {
  urlPosition: number | null
  selected: any
}

export type { Categories } from './Combination'
