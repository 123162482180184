import { Filters as FilterTypes } from '@/types'

import { Horizontal, Vertical, Mobile } from './variants'

type Props = {
  variant: 'vertical' | 'horizontal' | 'mobile'
  components: Array<FilterTypes.FilterItem>
  onFilter: (
    name: string,
    value: FilterTypes.FilterValue,
    changeUrl?: boolean
  ) => void
  filters: Record<string, any>
  onApplyFilters?: FilterTypes.onApplyFilters
  onResetAllFilter?: FilterTypes.onResetAllFilter
  onResetFilter?: FilterTypes.onResetFilter
  onShowMoreFilters?: FilterTypes.onShowMoreFilters
  filterCount: number
  className?: string
  group?: FilterTypes.Group
}

export default function Filters({
  variant,
  components,
  onFilter,
  filters,
  filterCount,
  onApplyFilters = () => {},
  onResetFilter = () => {},
  onResetAllFilter = () => {},
  onShowMoreFilters = () => {},
  className = '',
  group,
}: Props) {
  if (variant === 'vertical') {
    return (
      <Vertical
        components={components}
        onFilter={onFilter}
        filters={filters}
        className={className}
        onResetFilter={onResetFilter}
        onShowMoreFilters={onShowMoreFilters}
        group={group}
      />
    )
  }

  if (variant === 'horizontal') {
    return (
      <Horizontal
        components={components}
        onFilter={onFilter}
        filters={filters}
        className={className}
        onResetAllFilter={onResetAllFilter}
        onResetFilter={onResetFilter}
        onShowMoreFilters={onShowMoreFilters}
        group={group}
      />
    )
  }

  if (variant === 'mobile') {
    return (
      <Mobile
        components={components}
        onFilter={onFilter}
        filters={filters}
        filterCount={filterCount}
        onResetFilter={onResetFilter}
        onApplyFilters={onApplyFilters}
        onResetAllFilter={onResetAllFilter}
        onShowMoreFilters={onShowMoreFilters}
        className={className}
        group={group}
      />
    )
  }

  return null
}

export { useFilters } from './use-filters'
export { Combination as CombinationFilter } from './variants'
