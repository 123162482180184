import { Filters as Facets } from '@/types'
import { usePage, useDictionary } from '@/providers'
import { CombinationFilter as Filter, useFilters } from '@/components/Filters'

type Props = {
  componentId: number
  combination: string
  combinationLevel2: string
  facets: Facets.Facets
}

export default function CombinationFilter({
  componentId,
  combination,
  combinationLevel2,
  facets,
}: Props) {
  const d = useDictionary('combination_listing')
  const { pages } = usePage()

  const {
    filters,
    onFilter,
    resetFilter,
    resetAllFilters,
    applyFilters,
    filterCount,
    showMoreFilters,
  } = useFilters({}, componentId, pages, [combination, combinationLevel2])

  return (
    <section className='container mb-8 overflow-hidden'>
      <Filter
        components={[
          {
            title: d('filter_group.level_1_title'),
            filters: facets?.level1,
            group: {
              name: combination,
              position: 0,
            },
          },
          {
            title: d('filter_group.level_2_title'),
            filters: facets?.level2,
            group: {
              name: combinationLevel2,
              position: 1,
            },
          },
        ]}
        filters={filters}
        filterCount={filterCount}
        onFilter={onFilter}
        onResetFilter={resetFilter}
        onResetAllFilter={resetAllFilters}
        onApplyFilters={applyFilters}
        onShowMoreFilters={showMoreFilters}
      />
    </section>
  )
}
