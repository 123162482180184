'use client'

import React, { useState, ReactNode, useEffect } from 'react'
import {
  Accordion as AccordionTailwind,
  AccordionHeader,
  AccordionBody,
} from '@material-tailwind/react'
import Icon from '@/components/Icon'
import IconSvg from '@/components/IconSvg'

type Props = {
  children: ReactNode
  title: string | ReactNode
  active?: boolean
  className?: string
  icon?: string
  collapsedIcon?: string
  headerClassName?: string
  bodyClassName?: string
  onClick?: () => void
  loading?: boolean
}

export default function Accordion({
  children,
  title,
  active = false,
  className = 'mb-3 bg-body-bg-secondary px-4 text-left',
  collapsedIcon = 'arrow-up',
  icon = 'arrow-down',
  headerClassName = 'border-b-0 py-3 transition-colors',
  bodyClassName = 'pt-1',
  onClick = () => {},
  loading = false,
}: Props) {
  const [open, setOpen] = useState<boolean>(active)

  useEffect(() => {
    setOpen(active)
  }, [active])

  const handleOpenAccordion = async () => {
    if (onClick) {
      await onClick()
    }
    setOpen(!open)
  }

  return (
    <AccordionTailwind
      open={open}
      icon={
        loading ? (
          <IconSvg className='size-4' icon='loading' />
        ) : (
          <Icon
            className='block'
            icon={(open ? collapsedIcon : icon) as string}
            size='14px'
          />
        )
      }
      className={className}
    >
      <AccordionHeader
        onClick={handleOpenAccordion}
        className={headerClassName}
      >
        {title}
      </AccordionHeader>
      <AccordionBody className={bodyClassName}>{children}</AccordionBody>
    </AccordionTailwind>
  )
}
