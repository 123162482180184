'use client'

import { useState, useEffect } from 'react'
import Image from '@/components/Image'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
} from '@material-tailwind/react'
import Link from '@/components/Link'
import { SliderProps } from '@/components/Slider'
import { usePage, useDictionary } from '@/providers'
import SimilarProducts from '@/components/SimilarProducts'
import Icon from '@/components/Icon'
import { fetchSimilarProducts } from '@/utils/actions/product'
import { breakpoints } from '@/components/Slider'
import { Product, Badge } from '@/types'
import { Badges } from '@/components/Badges'

export type Props = {
  id: number
  href: string
  title: string
  mediaUrl: string
  mediaAlt: string
  mediaKey: string
  mediaBucket: string
  slug?: string | null
  badges?: Array<Badge.Badge>
  showSimilar?: boolean
  className?: string
}

export default function InspirationCard({
  id,
  href,
  title,
  mediaAlt,
  mediaUrl,
  mediaKey,
  mediaBucket,
  badges = [],
  slug = null,
  showSimilar = false,
  className = '',
}: Props) {
  const { pages } = usePage()
  const d = useDictionary('product')

  const sliderProps: SliderProps = {
    slidesPerView: 3.2,
    breakpoints: {
      [breakpoints.sm]: {
        slidesPerView: 4.2,
      },
      [breakpoints.md]: {
        slidesPerView: 6.2,
      },
      [breakpoints.lg]: {
        slidesPerView: 4.5,
      },
      [breakpoints.xl]: {
        slidesPerView: 4.5,
      },
      [breakpoints.xxl]: {
        slidesPerView: 4.5,
      },
    },
  }

  const [similarProducts, setSimilarProducts] =
    useState<Product.SimilarProducts | null>(null)
  const [loading, setLoading] = useState<boolean>(showSimilar!)

  useEffect(() => {
    if (showSimilar) {
      getSimilarProducts(id)
    }
  }, [showSimilar, id])

  const getSimilarProducts = async (productId: number) => {
    setLoading(true)

    try {
      const { data } = await fetchSimilarProducts(productId)

      setSimilarProducts(data)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      /* empty */
    }
    setLoading(false)
  }

  return (
    <Card
      color='transparent'
      shadow={false}
      className={`w-full max-w-[26rem] rounded-none ${className}`}
    >
      <CardHeader
        floated={false}
        shadow={false}
        color='transparent'
        className='m-0 rounded-none'
      >
        <Link className='block h-64' href={href} title={title}>
          <Image
            data-key={mediaKey}
            data-bucket={mediaBucket}
            data-id={id}
            className='creative-img-box absolute inset-x-0 inset-y-0 size-full object-cover object-scale-down object-center'
            src={mediaUrl}
            alt={mediaAlt}
            width={300}
            height={300}
          />
          {!!(badges && badges?.length) && <Badges badges={badges} />}
        </Link>
      </CardHeader>
      <CardBody className='mt-2 p-0'>
        <Typography
          variant='h3'
          className='line-clamp-[2] overflow-hidden text-ellipsis p-2 pt-0 text-left text-md font-normal text-primary'
        >
          <Link className='line-clamp-[2]' href={href} title={title}>
            {title}
          </Link>
        </Typography>

        <div className='flex flex-col justify-between gap-2 p-2 pt-0 md:flex-row md:items-center'>
          {slug && (
            <Link
              href={`/${pages.product}/${slug}`}
              className='flex items-center gap-1.5 text-left text-xs font-normal text-secondary'
            >
              <Icon icon='info' size='12px' /> {d('details_link')}
            </Link>
          )}
        </div>
      </CardBody>

      {showSimilar && (
        <CardFooter className='p-0 pb-1'>
          <SimilarProducts
            loading={loading}
            count={similarProducts?.count}
            products={similarProducts?.products}
            sliderProps={sliderProps}
            inspiration={{
              name: title,
              link: href,
            }}
          />
        </CardFooter>
      )}
    </Card>
  )
}
