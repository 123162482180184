import { Suggestions as SuggestionsType } from '@/types'
import Link from '@/components/Link'
import { Typography } from '@material-tailwind/react'
import Image from '@/components/Image'
import Slider, { SliderItem } from '@/components/Slider'

type Props = {
  list?: Array<SuggestionsType.Item>
  title?: string
  className?: string
  mainContent?: boolean
}

export default function Suggestions({
  list = [],
  title = '',
  className = '',
  mainContent = false,
}: Props) {
  if (!list.length) {
    return <></>
  }

  const sliderProps = {
    slidesPerView: 'auto' as const,
    spaceBetween: 8,
  }

  return (
    <div
      className={`my-4 bg-body-bg-secondary ${
        mainContent
          ? 'border !bg-[#ffeada] pb-5 pt-3 md:pb-7 md:pt-5'
          : 'py-3 pl-4'
      } ${className}`}
    >
      {title && (
        <Typography
          variant='h2'
          className={`mb-3 text-left ${mainContent ? 'px-3 md:px-5' : ''} text-base font-medium md:text-lg`}
        >
          {title}
        </Typography>
      )}
      <div
        className={`${
          mainContent ? 'ml-9 md:ml-14' : ''
        } flex flex-wrap items-center justify-items-start gap-1.5 ${className}`}
      >
        <Slider className={`!mx-0 overflow-visible`} {...sliderProps}>
          {list.map((item: SuggestionsType.Item, index: number) => (
            <SliderItem
              key={index}
              className={`border bg-white ${mainContent ? 'border-border-color' : ''} !min-w-36 !max-w-40`}
            >
              {(item.hasOwnProperty('image') && (
                <Link
                  href={item.url}
                  className={`flex flex-col ${mainContent ? 'h-48' : 'h-28'} background-white items-center`}
                  title={item.title}
                >
                  <Image
                    className='h-2/3 w-auto grow object-contain py-0.5'
                    src={item.image || ''}
                    alt={`${item.title} image`}
                    height={mainContent ? 160 : 90}
                    width={mainContent ? 160 : 90}
                    priority={!mainContent && index < 3}
                  />
                  <Typography
                    variant='h6'
                    className='w-full border px-1.5 py-1 text-center text-[10px] font-normal leading-tight'
                    aria-label={item.title}
                    role='heading'
                    aria-level={2}
                  >
                    {item.title}
                  </Typography>
                </Link>
              )) || (
                <Link
                  href={item.url}
                  className={`background-white grow`}
                  title={item.title}
                >
                  <Typography
                    variant='h6'
                    className='w-full grow border px-2 py-1.5 text-center text-xs font-normal'
                    aria-label={item.title}
                  >
                    {item.title}
                  </Typography>
                </Link>
              )}
            </SliderItem>
          ))}
        </Slider>
      </div>
    </div>
  )
}
