import { Fragment } from 'react'
import { Typography } from '@material-tailwind/react'
import { Blog, Breadcrumb, Thumbnails, PictureSources, Media } from '@/types'
import { usePage, useDictionary } from '@/providers'
import Breadcrumbs from '@/components/Breadcrumbs'
import BlogCard from '@/components/BlogCard'
import Pagination from '@/components/Pagination'

type Props = {
  blogs: Array<Blog.Blog>
  count: number
  limit: number
  displayPagination: boolean
  breadcrumb: Array<Breadcrumb>
  page?: number
}

export default function BlogListing({
  blogs,
  count,
  limit,
  page,
  displayPagination,
  breadcrumb,
}: Props) {
  const d = useDictionary('blog_listing')
  const { metaInformation } = usePage()

  const buildMediaUrl = (media?: Media): string => {
    if (media === undefined) {
      return ''
    }

    if (
      media.thumbnails === undefined ||
      !('750x750' in media.thumbnails) ||
      !('490x490' in media.thumbnails) ||
      !('330x330' in media.thumbnails)
    ) {
      return media.imageUrl
    }

    return media.thumbnails['330x330'] as string
  }

  const buildMediaSources = (
    thumbnails?: Thumbnails
  ): PictureSources | undefined => {
    if (
      thumbnails === undefined ||
      !('750x750' in thumbnails) ||
      !('490x490' in thumbnails) ||
      !('330x330' in thumbnails)
    ) {
      return
    }

    return {
      '360': {
        src: thumbnails['330x330'],
        size: 330,
      },
      '520': {
        src: thumbnails['490x490'],
        size: 490,
      },
      '767': {
        src: thumbnails['750x750'],
        size: 750,
      },
      '1023': {
        src: thumbnails['490x490'],
        size: 490,
      },
    }
  }

  return (
    <section className='container mb-8 overflow-hidden'>
      {!!(breadcrumb && breadcrumb.length) && (
        <Breadcrumbs breadcrumbs={breadcrumb} />
      )}
      <Typography
        variant='h1'
        className='mb-8 mt-4 text-left text-2xl font-semibold text-primary'
      >
        {metaInformation?.h1 || d('title')} {d('items', { count: `${count}` })}
      </Typography>
      <div className='mt-4 grid grid-cols-1 gap-y-8 md:grid-cols-2 md:gap-4 lg:grid-cols-4'>
        {(blogs.length > 0 &&
          blogs.map((item: Blog.Blog, index: number) => (
            <Fragment key={index}>
              <BlogCard
                id={index}
                mediaUrl={buildMediaUrl(item.media)}
                mediaBucket={item.media?.bucket}
                mediaKey={item.media?.key}
                mediaAlt={item.name}
                title={item.name}
                description={item.description}
                createdAt={item.createdAt}
                slug={item.slug}
                className={`max-w-full border border-transparent hover:border-light-silver-color`}
                imageProps={{
                  priority: index < 2,
                  loading: index < 6 ? 'eager' : 'lazy',
                  sources: buildMediaSources(item.media?.thumbnails),
                }}
              />
            </Fragment>
          ))) ||
          d('no_blogs_found')}
      </div>

      {displayPagination && (
        <Pagination
          activePage={page ?? 1}
          totalItemCount={count}
          itemsPerPage={limit}
        />
      )}
    </section>
  )
}
