'use client'

import React, { useState } from 'react'
import {
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Button,
} from '@material-tailwind/react'
import Icon from '@/components/Icon'
import { usePage, useDictionary, useLoader } from '@/providers'
import { signOut } from 'next-auth/react'
import FakeLink from '@/components/FakeLink'

export default function UserMenu() {
  const { loggedIn, pages } = usePage()
  const d = useDictionary('header')
  const { setLoading } = useLoader()

  const [openMenu, setOpenMenu] = useState(false)
  const onSignOut = () => {
    setLoading(true)
    signOut({ callbackUrl: `/${pages.signIn}` })
  }
  return (
    <Menu open={openMenu} handler={setOpenMenu}>
      <MenuHandler>
        <Button
          className='flex items-center justify-center p-0 shadow-none hover:shadow-none focus:shadow-none active:shadow-none sm:size-8'
          aria-label={d('account_label')}
        >
          <Icon icon='user2' className='text-lg leading-none sm:text-3xl' />
        </Button>
      </MenuHandler>
      {loggedIn ? (
        <MenuList>
          <MenuItem className='p-0'>
            <FakeLink
              className='px-2 py-3'
              href={`/${pages.account}?tab=general`}
            >
              {d('account_menu.general')}
            </FakeLink>
          </MenuItem>
          <MenuItem className='p-0'>
            <FakeLink
              className='px-2 py-3'
              href={`/${pages.account}?tab=settings`}
            >
              {d('account_menu.settings')}
            </FakeLink>
          </MenuItem>
          <MenuItem className='p-0'>
            <FakeLink className='px-2 py-3' href={`/${pages.account}?tab=help`}>
              {d('account_menu.help')}
            </FakeLink>
          </MenuItem>
          <hr className='my-2 border-blue-gray-50' />
          <MenuItem onClick={onSignOut}>{d('account_menu.signout')}</MenuItem>
        </MenuList>
      ) : (
        <MenuList>
          <MenuItem className='p-0'>
            <FakeLink className='px-2 py-3' href={`/${pages.signIn}`}>
              {d('account_menu.signin')}
            </FakeLink>
          </MenuItem>
        </MenuList>
      )}
    </Menu>
  )
}
