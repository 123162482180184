import Image from '@/components/Image'
import { HeaderComponent } from '@/types'
import Icon from '@/components/Icon'
import AjaxSearch from '@/components/AjaxSearch'
import Link from '@/components/Link'
import FakeLink from '@/components/FakeLink'
import OffCanvasMenu from '@/components/OffCanvasMenu'
import { usePage, useDictionary } from '@/providers'
import tailwindConfig from '@/utils/tailwind-config'
import AdvancedMenu from '@/components/AdvancedMenu'
import UserMenu from '@/components/UserMenu'

type Props = {
  config: HeaderComponent.Config
}

export default function Header({ config }: Props) {
  const { pages, channel, pixelImage } = usePage()
  const d = useDictionary('header')

  return (
    <header className='bg-white'>
      <div className='container'>
        <div className='flex w-full flex-wrap items-center justify-between gap-x-4 gap-y-3 md:flex-nowrap md:gap-x-12 md:border-b md:py-5'>
          <OffCanvasMenu menuItems={config.menuItems} />
          <div className='flex-1'>
            <Link className='relative block h-8 w-28 sm:w-36 md:h-11' href='/'>
              <Image
                className='max-w-none object-contain object-center'
                src={channel?.logo ?? pixelImage}
                alt={d('logo_alt')}
                fill
                sizes={`${tailwindConfig.theme.width[28]}, (min-width: ${tailwindConfig.theme.screens.sm}) ${tailwindConfig.theme.width[36]}`}
                priority={true}
              />
            </Link>
          </div>
          <AjaxSearch
            className={'order-1 w-full grow md:order-none'}
            searchSuggestion={config.search}
          />
          <div className='flex flex-1 items-center justify-end gap-x-3 sm:gap-x-6'>
            <FakeLink
              href={`/${pages.favorite}`}
              className='flex size-6 items-center justify-center sm:size-8'
              title={d('wishlist_label')}
            >
              <Icon
                icon='heart3'
                className='text-lg leading-none sm:text-3xl'
              />
            </FakeLink>
            <UserMenu />
          </div>
        </div>
        <AdvancedMenu menuItems={config.menuItems} />
      </div>
    </header>
  )
}
