import Image from '@/components/Image'
// import { Tooltip } from '@material-tailwind/react'
import WishlistButton from '@/components/WishlistButton'
import { Badges } from 'src/components/Badges'
import { Product, Badge } from '@/types'
import emitter, { DeleteFavoriteOutfitEvent } from '@/utils/events'

type Props = {
  mediaUrl: string
  mediaKey: string
  mediaBucket: string
  alt: string
  outfitId: number
  products?: Array<Product.Product>
  badges?: Array<Badge.Badge>
  isInspirationOutfit: boolean
  favorite?: boolean
  imageProps?: object
}

export function OutfitImage({
  badges,
  mediaUrl,
  mediaKey,
  mediaBucket,
  alt,
  outfitId,
  favorite = false,
  imageProps = {},
}: Props) {
  return (
    <div className='relative mt-0 md:mt-4'>
      <Image
        data-key={mediaKey}
        data-bucket={mediaBucket}
        data-id={outfitId}
        src={mediaUrl}
        alt={alt}
        width={556}
        height={746}
        className='creative-img-box absolute relative inset-x-0 inset-y-0 size-full object-scale-down object-center'
        {...imageProps}
      />

      {!!(badges && badges?.length) && <Badges badges={badges} />}

      <WishlistButton
        id={outfitId}
        type='outfit'
        isChecked={favorite}
        onDelete={() => emitter.emit(DeleteFavoriteOutfitEvent, outfitId)}
      />

      {/*{isInspirationOutfit &&*/}
      {/*  products?.map((el, index) => (*/}
      {/*    <Tooltip*/}
      {/*      key={index}*/}
      {/*      content={el.name}*/}
      {/*      placement='right'*/}
      {/*      offset={15}*/}
      {/*      className='rounded-sm bg-white text-black before:absolute before:-left-1 before:bottom-0 before:top-0 before:-z-10 before:my-auto before:h-4 before:w-4 before:rotate-45 before:bg-white'*/}
      {/*    >*/}
      {/*      <span*/}
      {/*        className='absolute z-10 flex h-4 w-4 cursor-pointer'*/}
      {/*        style={{*/}
      {/*          top: (el?.inspirationCordY ?? index) * 100 + '%',*/}
      {/*          left: (el?.inspirationCordX ?? index) * 100 + '%',*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        <span className='absolute inline-flex h-full w-full animate-ping rounded-full bg-white opacity-75' />*/}
      {/*        <span className='relative inline-flex h-4 w-4 rounded-full border border-white bg-black/70' />*/}
      {/*      </span>*/}
      {/*    </Tooltip>*/}
      {/*  ))}*/}
    </div>
  )
}
