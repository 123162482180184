'use client'
import { Filters } from '@/types'
import React, { useState, useCallback, useEffect } from 'react'
import { Input } from '@material-tailwind/react'
import MultiRangeSlider, { type ChangeResult } from 'multi-range-slider-react'
import HorizontalSelect from '@/components/Filters/components/HorizontalSelect'
// import './styles.scss'

type Props = {
  title: string
  name: string
  items: Filters.Range
  filter: Filters.SelectedData
  group?: Filters.Group
  onFilter: Filters.onFilter
  onResetFilter: Filters.onResetFilter
  urlPosition?: number | null
  mobile?: boolean
  selected?: Filters.Range | Record<string, number>
}

export default function PriceRange({
  title,
  items,
  filter,
  onFilter,
  name,
  selected = {},
  mobile = false,
  urlPosition = null,
  group,
}: Props) {
  const [startValue, setStartValue] = useState(
    selected?.minPrice || items.minPrice
  )
  const [endValue, setEndValue] = useState(selected?.maxPrice || items.maxPrice)
  const [filterApplied, setFilterApplied] = useState(false)

  const [startRange, setStartRange] = useState(
    selected?.minPrice || items.minPrice
  )
  const [endRange, setEndRange] = useState(selected?.maxPrice || items.maxPrice)
  const [changedStart, setChangedStart] = useState(false)
  const [changedEnd, setChangedEnd] = useState(false)

  useEffect(() => {
    if (selected && Object.keys(selected)?.length > 0) {
      setFilterApplied(true)
      setStartValue(selected.minPrice)
      setStartRange(selected.minPrice)
      setEndValue(selected.maxPrice)
      setEndRange(selected.maxPrice)
      onFilter(
        name,
        {
          urlPosition: urlPosition,
          title,
          selected: selected,
        },
        true,
        group
      )
    }
  }, [])

  useEffect(() => {
    if (
      !Object.keys(filter)?.length &&
      selected &&
      startValue === selected.minPrice &&
      endValue === selected.maxPrice
    ) {
      setStartValue(selected.minPrice || items.minPrice)
      setStartRange(selected.minPrice || items.minPrice)
      setEndValue(selected.maxPrice || items.maxPrice)
      setEndRange(selected.maxPrice || items.maxPrice)
      setFilterApplied(true)
    } else if (
      !Object.keys(filter)?.length &&
      (startValue !== items.minPrice || endValue !== items.maxPrice)
    ) {
      setStartValue(items.minPrice)
      setStartRange(items.minPrice)
      setEndValue(items.maxPrice)
      setEndRange(items.maxPrice)
      setFilterApplied(false)
    }
  }, [filter?.selected])

  useEffect(() => {
    if (startRange !== startValue) {
      setStartValue(startRange)
    }
    if (endRange !== endValue) {
      setEndValue(endRange)
    }
  }, [startRange, endRange])

  const handleStartBlur = (newValue: string) => {
    const intNewValue = parseInt(newValue, 10) || 0
    if (
      intNewValue < items.maxPrice &&
      intNewValue >= items.minPrice &&
      intNewValue !== (filter?.selected?.minPrice || items.minPrice)
    ) {
      setStartValue(intNewValue)
      setStartRange(intNewValue)
      setFilterApplied(true)
      setChangedStart(true)
      callFilter({ minPrice: intNewValue, maxPrice: endValue })
    } else if (intNewValue < items.minPrice || intNewValue >= items.maxPrice) {
      setStartValue(items.minPrice)
      setStartRange(items.minPrice)
    }
  }

  const handleEndBlur = (newValue: string) => {
    const intNewValue = parseInt(newValue, 10)
    if (intNewValue <= items.minPrice || intNewValue > items.maxPrice) {
      setEndValue(items.maxPrice)
      setEndRange(items.maxPrice)
      setFilterApplied(true)
      setChangedEnd(true)
      callFilter({ minPrice: startValue, maxPrice: items.maxPrice })
      return
    }

    if (
      intNewValue > items.minPrice &&
      intNewValue <= items.maxPrice &&
      intNewValue !== (filter?.selected?.maxPrice || items.maxPrice)
    ) {
      setEndValue(intNewValue)
      setEndRange(intNewValue)
      setFilterApplied(true)
      setChangedEnd(true)
      callFilter({ minPrice: startValue, maxPrice: intNewValue })
      return
    }
  }

  const handleChangeOfRange = (newMinValue: number, newMaxValue: number) => {
    if (
      newMinValue !== (filter?.selected?.minPrice || items.minPrice) ||
      newMaxValue !== (filter?.selected?.maxPrice || items.maxPrice)
    ) {
      setStartValue(newMinValue)
      setStartRange(newMinValue)
      setEndValue(newMaxValue)
      setEndRange(newMaxValue)
      setFilterApplied(true)
      callFilter({ minPrice: newMinValue, maxPrice: newMaxValue })
    }
  }

  const callFilter = useCallback(
    (_selected: Filters.Range) => {
      onFilter(
        name,
        {
          urlPosition: urlPosition || null,
          title,
          selected: _selected,
        },
        mobile,
        group
      )
    },
    [startValue, endValue, onFilter]
  )

  const renderContent = (
    <div className='w-full focus-visible:outline-none'>
      <div className='mb-4 flex items-center justify-between'>
        <Input
          id='price-min-value'
          value={startValue}
          type='number'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setStartValue(parseInt(e.target.value || `0`, 10))
            setChangedStart(false)
          }}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleStartBlur(e.target.value)
          }
          className='w-auto'
          containerProps={{
            className: '!w-1/3 !min-w-0',
          }}
          aria-label='Range start'
        />
        <Input
          id='price-max-value'
          value={endValue}
          type='number'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setEndValue(parseInt(e.target.value || `0`, 10))
            setChangedEnd(false)
          }}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleEndBlur(e.target.value)
          }
          className='w-auto'
          containerProps={{
            className: '!w-1/3 !min-w-0',
          }}
          aria-label='Range end'
        />
      </div>
      <MultiRangeSlider
        id='price-range'
        min={items.minPrice}
        max={items.maxPrice}
        step={5}
        minValue={startRange}
        maxValue={endRange}
        ruler={false}
        label={false}
        baseClassName='range'
        style={{ border: 'none', boxShadow: 'none' }}
        onChange={(e: ChangeResult) => {
          if (
            startValue === startRange &&
            startRange === e.minValue &&
            endValue === endRange &&
            endRange === e.maxValue
          ) {
            handleChangeOfRange(e.minValue, e.maxValue)
          }
        }}
        onInput={(e: ChangeResult) => {
          if (startValue !== startRange) {
            setStartRange(e.minValue)
          } else if (startValue === startRange && !changedStart) {
            setStartRange(e.minValue)
          }

          if (endValue !== endRange) {
            setEndRange(e.maxValue)
          } else if (endValue === endRange && !changedEnd) {
            setEndRange(e.maxValue)
          }

          if (startValue === startRange && startRange === e.minValue) {
            setChangedStart(false)
          }

          if (endValue === endRange && endRange === e.maxValue) {
            setChangedEnd(false)
          }
        }}
      />
    </div>
  )

  if (mobile) {
    return (
      <div className='mb-4 mt-2'>
        <div className='mb-2 mt-5 text-left text-base font-medium'>{title}</div>
        {renderContent}
      </div>
    )
  }

  return (
    <HorizontalSelect title={title} isSelected={filterApplied}>
      {renderContent}
    </HorizontalSelect>
  )
}
