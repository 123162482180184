'use client'

import {
  MouseEvent as ReactMouseEvent,
  TouchEvent as ReactTouchEvent,
} from 'react'

import { useState, useEffect } from 'react'
import { Spinner } from '@material-tailwind/react'
import Slider, {
  SliderItem,
  breakpoints,
  SliderProps,
} from '@/components/Slider'
import VariantItem from './VariantItem'
import { Outfit, Product } from '@/types'
import Link from '@/components/Link'
import Icon from '@/components/Icon'
import {
  CurrentPageTrackingData,
  TrackingExternalUrlSpecificData,
  GetExternalLinkTrackingUrlParams,
  GetExternalLinkTrackingProductParams,
  BuildHref,
} from '@/components/NgTracker'

type ProductVariantSliderProps = {
  variants: Array<Product.Product>
  className?: string
  onClick?: (
    event:
      | ReactMouseEvent<HTMLElement, MouseEvent>
      | ReactTouchEvent<HTMLElement>,
    id: number,
    name: string,
    link: string
  ) => void
  activeId?: number
  sliderProps?: SliderProps
  isLoading?: boolean
  inspiration?: Outfit.InspirationOutfit | null
  componentTrackingSpecificData?: TrackingExternalUrlSpecificData | undefined
}

export default function ProductVariantSlider({
  variants,
  activeId,
  sliderProps = {},
  className = '',
  onClick = () => {},
  isLoading,
  inspiration,
  componentTrackingSpecificData = {},
}: ProductVariantSliderProps) {
  const [trackingParams, setTrackingParams] = useState('')

  useEffect(() => {
    if (CurrentPageTrackingData && variants.length) {
      setTrackingParams(
        GetExternalLinkTrackingUrlParams(componentTrackingSpecificData)
      )
    }
  }, [CurrentPageTrackingData])

  if (!variants.length) {
    return
  }

  const sliderSettings: SliderProps = {
    modules: undefined,
    nested: true,
    navigation: {
      enabled: false,
    },
    slidesPerView: 5.5,
    breakpoints: {
      [breakpoints.sm]: {
        slidesPerView: 5.5,
      },
      [breakpoints.md]: {
        slidesPerView: 4.5,
      },
      [breakpoints.lg]: {
        slidesPerView: 4.5,
      },
      [breakpoints.xl]: {
        slidesPerView: 4.5,
      },
      [breakpoints.xxl]: {
        slidesPerView: 4.5,
      },
    },
    ...sliderProps,
  }

  return (
    <Slider {...sliderSettings} className={className}>
      {variants?.map((product, index) => (
        <SliderItem
          key={index}
          onClick={(event) =>
            onClick?.(event, product.id, product.name, product.link)
          }
          onTouchEnd={(event) =>
            onClick?.(event, product.id, product.name, product.link)
          }
          className='relative !h-auto'
        >
          {isLoading && activeId === product?.id && (
            <div className='absolute bottom-0 left-0 right-0 top-0 z-20 flex items-center justify-center bg-black/[0.4]'>
              <Spinner className='h-4 w-4' />
            </div>
          )}
          <VariantItem
            title={product.price}
            href={
              product.subType !== Outfit.InspirationOutfitType
                ? BuildHref(
                    product.link,
                    trackingParams,
                    GetExternalLinkTrackingProductParams(
                      product.id,
                      product.shopId
                    )
                  )
                : ''
            }
            id={product.id}
            previousPrice={product.previousPrice}
            hasSale={product.hasSale}
            target={product.fakeLink !== false ? '_blank' : '_self'}
            mediaUrl={product?.media?.imageUrl ?? ''}
            mediaAlt={product.name}
            mediaBucket={product.media?.bucket}
            mediaKey={product.media?.key}
            isActive={activeId === product?.id}
            badges={product.badges}
            className='h-full'
            fakeLink={product.fakeLink !== false}
          />
        </SliderItem>
      ))}
      {inspiration && (
        <SliderItem className='!h-auto'>
          <Link
            className='flex h-full w-full cursor-pointer items-center justify-center rounded-none border border-light-cyan-color hover:border-light-silver-color'
            href={inspiration.link}
            title={inspiration.name}
          >
            <Icon
              className='block text-light-silver-color'
              icon='arrow-right'
              size='24px'
            />
          </Link>
        </SliderItem>
      )}
    </Slider>
  )
}
