import { Page, Filters } from '@/types'
import { FilterValue } from '@/types/Filters'

type Data = {
  [key: string]: any
}

interface Filters {
  [key: string]: FilterValue | Filters
}

interface Item {
  slug: string
  children?: Item[]
}

const generateFirstSegment = (
  pathname: string,
  data: Data,
  initialPages: Page.Pages,
  basePath: string,
  urlSegments: { position: number; segment: string }[],
  initialGroups?: string[]
): string => {
  const splitBasePath = basePath.split('/')
  const splitPathname = pathname.split('/')

  let firstSegment = splitPathname[1] || ''

  const atLeastOneExistsPathname = initialGroups?.some((item) =>
    splitPathname.includes(item)
  )
  const atLeastOneExistsBasePath = initialGroups?.some((item) =>
    splitBasePath.includes(item)
  )

  if (
    data?.hasSale &&
    data?.hasSale.selected &&
    firstSegment !== initialPages?.sale
  ) {
    firstSegment = initialPages?.sale || ''
  } else if (
    data?.brand &&
    data?.brand?.selected?.length <= 1 &&
    urlSegments?.length <= 1 &&
    initialPages?.outfits !== firstSegment &&
    firstSegment !== initialPages?.sale &&
    !data?.hasSale
  ) {
    firstSegment = initialPages?.brand || ''
  } else if (!basePath && atLeastOneExistsPathname) {
    firstSegment = initialPages.combinationHome || ''
  } else if (basePath && atLeastOneExistsBasePath) {
    firstSegment = initialPages?.products || ''
  } else if (
    initialPages?.products === firstSegment ||
    (data?.brand &&
      urlSegments?.length > 1 &&
      data?.brand?.selected?.length > 1) ||
    (firstSegment === initialPages?.sale && !data?.hasSale)
  ) {
    firstSegment = initialPages?.products || ''
  }

  return firstSegment
}

const generateUrl = (
  pathname: string,
  data: Data,
  initialPages: Page.Pages,
  initialGroups?: string[] | undefined,
  groupPositions?: Filters.GroupPositions
): string => {
  const urlSegments: { position: number; segment: string }[] = []
  const queryParams: { [key: string]: any } = {}

  const processItem = (group: Filters, groupName?: string) => {
    const localUrlSegments: { position: number; segment: string }[] = []

    for (const key in group) {
      if (Object.prototype.hasOwnProperty.call(group, key)) {
        const item = group[key]
        const position = item?.urlPosition as number

        if (item?.selected) {
          if (Array.isArray(item.selected)) {
            // Handle array of selected items
            const selectedSlugs = item.selected
              .map((selectedItem: any) => selectedItem.slug)
              .filter((slug: string | null) => slug !== null)
              .join('--')

            if (position !== null && selectedSlugs) {
              localUrlSegments.push({ position, segment: selectedSlugs })
            } else {
              const values = item.selected
                .map((selectedItem: any) => selectedItem.value)
                .filter((value: any) => value !== undefined && value !== null)
              if (values.length > 0) {
                queryParams[key] = values.length > 1 ? values : values[0]
              }
            }
          } else if (
            typeof item.selected === 'object' &&
            item.selected !== null
          ) {
            for (const subKey in item.selected) {
              if (Object.prototype.hasOwnProperty.call(item.selected, subKey)) {
                const value = item.selected[subKey]
                if (value !== undefined && value !== null) {
                  queryParams[subKey] =
                    typeof value === 'boolean' ? value.toString() : value
                }
              }
            }
          } else if (item.selected && key !== 'hasSale') {
            queryParams[key] = item.selected.toString()
          }
        }
      }
    }

    localUrlSegments.sort((a, b) => a.position - b.position)
    const path = localUrlSegments
      .map((segmentObj) => segmentObj.segment)
      .join('--')
    const segment =
      groupName === Filters.GroupNames.OTHER
        ? `${path}`
        : `${groupName}/${path}`

    const position =
      groupName && groupPositions ? groupPositions[groupName] || 0 : 0

    urlSegments.push({ position, segment })
  }

  const processGroup = (group: Filters) => {
    for (const key in group) {
      if (Object.prototype.hasOwnProperty.call(group, key)) {
        const item = group[key]
        processItem(item as Filters, key)
      }
    }
  }

  const processGenerateGroup = (data: Filters) => {
    let groups: Filters = {}

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const item = data[key]
        if (
          typeof item === 'object' &&
          item !== null &&
          !('selected' in item)
        ) {
          groups = { ...groups, [key]: item }
        } else {
          groups = {
            ...groups,
            [Filters.GroupNames.OTHER]: {
              ...groups[Filters.GroupNames.OTHER],
              [key]: item,
            },
          }
        }
      }
    }

    processGroup(groups)
  }

  processGenerateGroup(data)

  urlSegments.sort((a, b) => a.position - b.position)

  let basePath = urlSegments.map((segmentObj) => segmentObj.segment).join('/')
  const splitBasePath = basePath.split('/')

  const allGroupsItemsExist = initialGroups?.every((item) =>
    splitBasePath.includes(item)
  )

  if (allGroupsItemsExist && initialGroups?.length) {
    basePath = splitBasePath.slice(1).join('/')
  }

  const queryString = new URLSearchParams(queryParams as any).toString()
  const firstSegment = generateFirstSegment(
    pathname,
    data,
    initialPages,
    basePath,
    urlSegments,
    initialGroups
  )

  return `/${firstSegment}/${basePath}${queryString ? `?${queryString}` : ''}`
}

export const findSelectedItems = (data: Item[], selected: string[]): Item[] => {
  const result: Item[] = []

  const recursiveFind = (items: Item[]) => {
    items.forEach((item) => {
      if (selected.includes(item.slug)) {
        result.push(item)
      }
      if (item.children) {
        recursiveFind(item.children)
      }
    })
  }

  recursiveFind(data)
  return result
}

export const isFilters = (value: any): value is Filters => {
  return typeof value === 'object' && value !== null && !('selected' in value)
}

export const transformFiltersToFetchParams = (
  filters: Filters,
  componentId: number,
  aliasName: string
) => {
  const transformedFilters: { [key: string]: string[] } = {}

  Object.entries(filters).forEach(([filterKey, filterValue]) => {
    if (filterValue && Array.isArray(filterValue.selected)) {
      transformedFilters[filterKey] = filterValue.selected.map(
        (item) => item.slug
      )
    }
  })

  return {
    componentId: componentId,
    propertyAlias: aliasName,
    filters: transformedFilters,
  }
}

export const changeUrlParams = (
  filters: Filters,
  router: any,
  pathname: string,
  initialPages: Page.Pages,
  initialGroups?: string[] | undefined,
  onLoading?: (isLoading: boolean) => void,
  groupPositions?: Filters.GroupPositions
) => {
  const generatedUrl = generateUrl(
    pathname,
    filters,
    initialPages,
    initialGroups,
    groupPositions
  )

  onLoading?.(true)

  window.open(generatedUrl, '_self' as string)
  // router.push(generatedUrl, undefined, { shallow: true });
}
