'use client'

import { ReactNode, useCallback, useEffect, useState } from 'react'
import { Filters } from '@/types'
import Accordion from '@/components/Accordion'
import SearchInput from '@/components/SearchInput'
import { Button, ListItemPrefix, Typography } from '@material-tailwind/react'
import { useDictionary } from '@/providers'

import { OffCanvas } from './OffCanvas'
import FilterLink from './FilterLink'
import { useFilterSearch } from '../use-filter-search'
import { findSelectedItems } from '../utils'
import Icon from '@/components/Icon'
import Loader from '@/components/Loader'
import { CustomList, CustomListItem } from '@/components/CustomList'

type Props = {
  title: string
  name: string
  onFilter: Filters.onFilter
  onResetFilter: Filters.onResetFilter
  onShowMoreFilters: Filters.onShowMoreFilters
  items: Array<Filters.FilterItem>
  mainContentFilter: boolean
  filter: Filters.SelectedData
  group?: Filters.Group
  mobile?: boolean
  horizontal?: boolean
  searchable?: boolean
  urlPosition?: number | null
  selected?: Array<string>
}

export default function Tree({
  title,
  name,
  items,
  filter,
  group,
  urlPosition = null,
  onFilter,
  onResetFilter,
  onShowMoreFilters,
  searchable,
  mobile = false,
  mainContentFilter = false,
  selected = [],
}: Props) {
  const d = useDictionary('filters')
  const [listItems, setListItems] = useState<Array<Filters.FilterItemListItem>>(
    items || []
  )
  const [getListItemsSuccess, setGetListItemsSuccess] = useState(false)
  const [loading, setLoading] = useState({
    search: false,
    button: false,
    loadMore: false,
  })

  const { filteredData, applyFilterSearch } = useFilterSearch(listItems)
  const [selectedItem, setSelectedItem] = useState<Array<string>>([])

  useEffect(() => {
    if (selected?.length > 0) {
      const selectedItems = findSelectedItems(filteredData, selected)

      setSelectedItem(selected)

      if (selectedItems.length) {
        onFilter(
          name,
          { urlPosition: urlPosition, title, selected: selectedItems },
          true,
          group
        )
      }
    }
  }, [])

  useEffect(() => {
    if (!Object.keys(filter)?.length && selectedItem?.length) {
      setSelectedItem([])
    }
  }, [filter?.selected])

  const handleSelectItem = useCallback(
    (item: Filters.FilterItemListItem | null) => {
      if (item) {
        onFilter(
          name,
          { urlPosition: urlPosition, title, selected: item ? [item] : null },
          mobile,
          group
        )
        setSelectedItem([item?.slug])
      } else {
        onResetFilter(name, !mobile, group)
      }
    },
    [name, onFilter, urlPosition]
  )

  const handleShowMore = async (type: string) => {
    setLoading({
      ...loading,
      [type]: true,
    })

    const newListItems = await onShowMoreFilters(name)

    setListItems(newListItems)
    setGetListItemsSuccess(true)
    setLoading({
      ...loading,
      [type]: false,
    })

    return true
  }
  const handleSearch = async (value: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    mainContentFilter && !getListItemsSuccess
      ? await handleShowMore('search')
      : () => {}
    applyFilterSearch(value)
  }

  const showMore =
    mainContentFilter && listItems?.length <= 0 ? handleShowMore : () => {}

  const renderAccordion = (
    data: Array<Filters.FilterItem>,
    level: number
  ): ReactNode => {
    return data?.map((item, index) => {
      if (!item.children) {
        return (
          <CustomListItem
            selected={selectedItem?.includes(item.slug)}
            title={item.title || item.value}
            aria-label={item.title || item.value}
            key={index}
            className={`rounded-none px-0.5 py-2 text-primary ${level !== 0 ? 'pl-3' : ''}`}
            onClick={() => handleSelectItem(item)}
          >
            <FilterLink
              fake={!item.indexable}
              href={item.url}
              title={item?.title || item?.value}
              ariaLabel={item?.title || item?.value}
              className='pointer-events-none block w-full text-left text-base'
            >
              {item?.value || item.title}{' '}
              <span className='text-secondary'>{item.count ?? ''}</span>
            </FilterLink>
          </CustomListItem>
        )
      }

      return (
        <Accordion
          key={index}
          active
          title={
            <span className='block w-full text-left text-base'>
              {item?.value || item.title}
              <span className='text-secondary'>{item.count}</span>
            </span>
          }
          icon='plus'
          collapsedIcon='minus'
          className={`m-0 border-0`}
          headerClassName='text-base border-0 py-2'
        >
          <div className='border-t p-0'>
            {!selectedItem?.includes(item.slug) && (
              <CustomListItem
                title={item.title || item.value}
                aria-label={item.title || item.value}
                key={index}
                className={`rounded-none px-0.5 py-2 text-primary`}
                onClick={() => handleSelectItem(item)}
              >
                <ListItemPrefix className='mr-2'>
                  <Icon className='block' size='12px' icon='arrow-left' />
                </ListItemPrefix>

                <FilterLink
                  fake={!item.indexable}
                  href={item.url}
                  title={item?.title || item?.value}
                  ariaLabel={item?.title || item?.value}
                  className='pointer-events-none'
                >
                  {d('filter_back_btn')} {item?.value || item.title}
                </FilterLink>
              </CustomListItem>
            )}

            {item.children && renderAccordion(item.children, level + 1)}
          </div>
        </Accordion>
      )
    })
  }

  const renderContent = (
    <div className='relative w-full focus-visible:outline-none'>
      {searchable && (
        <SearchInput
          onChange={handleSearch}
          ariaLabel={d('hidden')}
          searchIconSize='12px'
          inputClassName='text-md !px-7 !py-1 mb-3'
          searchIconClassName='!left-1'
          cleaButtonClassName='h-5 w-5'
          disabled={loading.search}
          containerProps={{
            className: 'h-7',
          }}
        />
      )}

      <CustomList
        className={`grid border-t p-0 ${!mobile ? 'max-h-[33.375rem] overflow-y-auto' : ''}`}
      >
        {!filteredData.length ? (
          <Typography variant='lead' className='p-2'>
            {d('filter_back_btn')}
          </Typography>
        ) : (
          <>
            {selected?.length && (
              <CustomListItem
                title={d('filter_back_btn')}
                aria-label={d('filter_back_btn')}
                className={`rounded-none px-0.5 py-2 text-primary`}
                onClick={() => handleSelectItem(null)}
              >
                <ListItemPrefix className='mr-2'>
                  <Icon className='block' size='12px' icon='arrow-left' />
                </ListItemPrefix>

                <span>{d('filter_back_btn')}</span>
              </CustomListItem>
            )}

            {renderAccordion(filteredData as Array<Filters.FilterItem>, 0)}
          </>
        )}
      </CustomList>

      {mainContentFilter && !getListItemsSuccess && (
        <Button
          loading={loading.button}
          className='mt-4 flex h-8 w-full items-center justify-center border px-2 py-1 text-center text-sm font-medium capitalize text-secondary !shadow-none'
          onClick={() => handleShowMore('button')}
          aria-label={d('more_filters')}
        >
          {d('more_filters')}
        </Button>
      )}

      <Loader
        loading={loading.search}
        className='absolute bg-black/10'
        iconClassName='!fill-black'
      />
    </div>
  )

  if (mobile) {
    return (
      <OffCanvas
        title={title}
        isSelected={!!selectedItem?.length}
        onClick={() => showMore('loadMore')}
        loading={loading.loadMore}
      >
        {renderContent}
      </OffCanvas>
    )
  }

  return (
    <Accordion
      title={title}
      active={!!selectedItem?.length || !!items?.length}
      icon='plus'
      collapsedIcon='minus'
      className='m-0 border px-3'
      headerClassName='text-base border-0'
      onClick={() => showMore('loadMore')}
      loading={loading.loadMore}
    >
      {renderContent}
    </Accordion>
  )
}
