import { StaticTextComponent } from '@/types'
import styles from './styles.module.scss'

export default function StaticDescription({
  content,
}: StaticTextComponent.Config) {
  return (
    <section
      className={`container ${styles.link} my-12 text-left`}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}
